<template>
	<v-btn
		v-if="offer.category.external && offer.applied"
		raised
		color="success"
		:loading="loadingParent"
		outlined
		target="_blank"
		class="ml-4"
		@click="callApplyOffer()"
		rounded
	>
		<span>{{ $t('applications.moreInformation') }} </span>
		<v-icon>mdi-open-in-new</v-icon>
	</v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'OfferApplyButtonExternal',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer',
		}),
	},
	methods: {
		callApplyOffer() {
			window.open(this.offer.externalLink, '_blank')
			this.applyOfferExternal(this.offer.id)
		},
		...mapActions('offers', ['applyOfferExternal']),
	},
}
</script>
